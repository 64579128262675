<template>
  <div>
    <v-simple-table height="calc(100vh - 260px)" class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr v-if="errTexts.length > 0" style="font-size: 12px">
            <td colspan="13">
              <div class="yellow error--text pa-3">
                <ul>
                  <li
                    v-for="(txt, ti) in errTexts"
                    :key="`txterr_${ti}`"
                    v-html="txt"
                  ></li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <th role="columnheader" class="text-center vertical-align-middle">
              #
            </th>
            <th
              role="columnheader"
              class="text-center vertical-align-middle"
              v-if="customer.is_multiple_shop"
            >
              {{ $t("labels.shop") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.customer_order_id") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.receiver") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.phone_number") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.city") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.district") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.ward") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.address") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.cash_on_delivery") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.note") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.product") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item.id"
            class="text-center"
            :class="{ 'error--text': errRows.includes(item.tt) }"
          >
            <td>{{ item.tt }}</td>
            <td v-if="customer.is_multiple_shop">{{ item.shop }}</td>
            <td>{{ item.customer_order_id }}</td>
            <td>{{ item.receiver_name }}</td>
            <td>{{ item.receiver_phone_number }}</td>
            <td>{{ item.city_name }}</td>
            <td>{{ item.county_name }}</td>
            <td>{{ item.ward_name }}</td>
            <td>{{ item.address }}</td>
            <td>{{ formatNumber(item.cash_on_delivery) }}</td>
            <td>{{ item.note }}</td>
            <td>
              <div
                v-for="(goods, gi) in item.goods_items || []"
                :key="`${index}_${gi}_${goods.barcode}`"
              >
                {{ goods.barcode }} x {{ goods.quantity }}
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-row>
        <v-col cols="4">
          <v-simple-table
            class="table-padding-2-no-top table-h-36 table-border-all w-100"
          >
            <template v-slot:default>
              <tbody>
                <tr class="font-weight-medium text-center">
                  <td colspan="3">{{ $t("labels.phone_number") }}</td>
                </tr>
                <tr class="text-center">
                  <td>{{ $t("labels.correct") }}</td>
                  <td>{{ $t("labels.incorrect") }}</td>
                  <td>{{ $t("labels.not_yet") }}</td>
                </tr>
                <tr class="text-center">
                  <td>{{ checkPhone.countCorrect }}</td>
                  <td>{{ checkPhone.countIncorrect }}</td>
                  <td>{{ checkPhone.countNotYet }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>

        <v-col cols="4"></v-col>

        <v-col cols="4">
          <v-simple-table
            class="table-padding-2-no-top table-h-36 table-border-all w-100"
          >
            <template v-slot:default>
              <tbody>
                <tr class="font-weight-medium text-center">
                  <td colspan="3">{{ $t("labels.goods") }}</td>
                </tr>
                <tr class="text-center">
                  <td>{{ $t("labels.correct") }}</td>
                  <td>{{ $t("labels.incorrect") }}</td>
                  <td>{{ $t("labels.not_yet") }}</td>
                </tr>
                <tr class="text-center">
                  <td>{{ checkGoods.countCorrect }}</td>
                  <td>{{ checkGoods.countIncorrect }}</td>
                  <td>{{ checkGoods.countNotYet }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </div>

    <div class="pt-3 d-flex justify-space-between align-center">
      <div class="d-flex justify-start align-center">
        <v-autocomplete
          class="c-input-xs mr-2"
          style="max-width: 180px"
          v-model="sourceType"
          :items="sourceTypeOptions"
          :label="$t('labels.channel')"
          :placeholder="$t('labels.channel')"
          dense
          outlined
          hide-details
        ></v-autocomplete>

        <v-autocomplete
          class="c-input-xs mr-2"
          style="max-width: 180px"
          v-model="source"
          :items="sourceOptions"
          :label="$t('labels.shop')"
          :placeholder="$t('labels.shop')"
          dense
          outlined
          hide-details
        ></v-autocomplete>

        <v-autocomplete
          class="c-input-xs mr-2"
          style="max-width: 180px"
          v-model="logicPhone"
          :items="logicPhoneOptions"
          :label="$t('labels.create_logic_phone')"
          :placeholder="$t('labels.create_logic_phone')"
          dense
          outlined
          hide-details
        ></v-autocomplete>

        <v-autocomplete
          class="c-input-xs mr-2"
          style="max-width: 180px"
          v-model="logicLead"
          :items="logicLeadOptions"
          :label="$t('labels.create_logic_lead')"
          :placeholder="$t('labels.create_logic_lead')"
          dense
          outlined
          hide-details
        ></v-autocomplete>

        <a
          class="mr-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default default"
          :href="templateLink"
          target="_blank"
        >
          <v-icon>mdi-download</v-icon>
          {{ $t("labels.sample_file") }}
        </a>

        <v-btn color="info" @click="$refs.inputUploadFile.click()"
          ><v-icon>mdi-upload</v-icon>
          {{ $t("labels.upload") }}
        </v-btn>

        <input
          type="file"
          ref="inputUploadFile"
          accept=".xlsx"
          @change="onInputFileChange"
          class="d-none"
        />
      </div>
      <div class="d-flex justify-end align-center" style="min-width: 300px">
        <span class="fs-14px mr-2">
          {{ $t("labels.sum_count", { count: items.length }) }}
        </span>
        <v-btn
          color="success"
          @click="createLeads"
          :disabled="isDisabledBtnSubmit"
        >
          <v-icon>mdi-check</v-icon>
          {{ $t("labels.lead_create") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { read, utils } from "xlsx";
import { httpClient } from "@/libs/http";
import {
  CREATE_PHONE_LOGICS,
  CREATE_LEAD_LOGICS,
  LEAD_MODELS,
} from "@/libs/const";
import _ from "lodash";

export default {
  name: "LeadCreate",
  components: {},
  data: () => ({
    isLoading: false,
    goods: [],
    items: [],
    sourceTypes: [],
    sources: [],
    leadModelOptions: [...LEAD_MODELS],
    logicPhoneOptions: [...CREATE_PHONE_LOGICS],
    logicLeadOptions: [...CREATE_LEAD_LOGICS],
    customer: {},
    page: 1,
    totalPage: 1,
    errRows: [],
    errTexts: [],
    sourceType: null,
    source: null,
    processModel: null,
    logicPhone: null,
    logicLead: null,
    leadModel: null,
    file: null,
    createFormat: "ssc",
  }),
  computed: {
    sourceTypeOptions() {
      return [...this.sourceTypes].map((st) => ({
        text: st.name,
        value: st.id,
      }));
    },
    sourceOptions() {
      if (!this.sourceType && this.sourceType !== 0) {
        return [];
      }

      return [...this.sources]
        .filter((s) => s.type == this.sourceType)
        .map((s) => ({
          text: s.name,
          value: s.id,
        }));
    },
    isDisabledBtnSubmit() {
      return this.items.length === 0 || this.errRows.length > 0;
    },
    templateLink() {
      return `${process.env.VUE_APP_FILE_CDN_URL}/templates/template_create_orders.xlsx`;
    },
    checkPhone() {
      const data = {
        countCorrect: 0,
        countIncorrect: 0,
        countNotYet: 0,
      };

      if (this.items && this.items.length > 0) {
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i];
          if (!item.receiver_phone_number) {
            data.countNotYet++;
          } else {
            const isValidPhone = this.isPhoneValidByCountry({
              phone: item.receiver_phone_number,
              id_county: this.customer.id_county,
            });
            if (!isValidPhone) {
              data.countIncorrect++;
            } else {
              data.countCorrect++;
            }
          }
        }
      }

      return data;
    },
    checkGoods() {
      const data = {
        countCorrect: 0,
        countIncorrect: 0,
        countNotYet: 0,
      };

      if (this.items && this.items.length > 0) {
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i];
          if (!item.goods_items || item.goods_items.length === 0) {
            data.countNotYet++;
          } else {
            const noIds = [...item.goods_items].filter(
              (gItem) => !gItem.id_goods
            );
            console.log(noIds);
            if (!noIds || noIds.length === 0) {
              data.countCorrect++;
            } else {
              data.countIncorrect++;
            }
          }
        }
      }

      return data;
    },
    dataConverted() {
      // Gom đơn cùng SĐT
      if (this.logicLead === 4) {
        // gom theo st
      }

      return this.items;
    },
  },
  created() {
    const { customer } = window.me;
    this.customer = { ...customer };
  },
  mounted() {
    this.getSourceType();
    this.getSources();
  },
  methods: {
    readBySsc(values) {
      const mappingFields = {
        "Tên ShopShop": "shop",
        "Mã đơn hàngOrder ID": "customer_order_id",
        "Người NhậnReceiver": "receiver_name",
        "Số Điện ThoạiReceiver Number": "receiver_phone_number",
        "Tỉnh thànhProvince": "city_name",
        "Quận huyệnCity|Municipal": "county_name",
        "Phường xãBarangay|Ward": "ward_name",
        "Địa chỉAddress": "address",
        "Tiền thu hộCash on Delivery": "cash_on_delivery",
        "Ghi ChúNote": "note",
        BarcodeBarcode: "barcodes",
        "Số LượngQuantity": "quantities",
      };
      let items = [...values].map((v, index) => {
        const item = {
          tt: index + 1,
        };
        Object.keys(v).forEach((i) => {
          const val = this.replaceEmojiChar((`${v[i]}` || "").trim());
          const mapkey = i.replace(/\r?\n|\r/g, "");
          item[mappingFields[mapkey]] = Buffer.from(
            `${val}`,
            "utf-8"
          ).toString();
        });
        const barcodes = (item.barcodes && item.barcodes.split(";")) || [];
        const quantities =
          (item.quantities && item.quantities.split(";")) || [];
        item.customer_order_id =
          item.customer_order_id || this.generateRandomString(8);
        const goods_items = [...barcodes].map((b, i) => {
          return {
            barcode: (b && b.trim()) || "",
            quantity: +((quantities[i] && quantities[i].trim()) || ""),
          };
        });
        item.goods_items = [...goods_items];
        item.receiver_phone_number = this.convertPhoneNumber({
          phone: item.receiver_phone_number,
          id_country: this.customer.id_country,
        });
        return item;
      });

      let data = [];
      let tt = 1;
      items.forEach((item) => {
        const checkItem = data.find(
          (d) => d.customer_order_id == item.customer_order_id
        );
        if (checkItem) {
          const checkItemIndex = data.findIndex(
            (d) => d.customer_order_id == item.customer_order_id
          );
          const checkGoodsBarcodes =
            [...checkItem.goods_items].map((ci) => ci.barcode) || [];

          const goodsItems =
            [...item.goods_items].filter((gi) =>
              checkGoodsBarcodes.includes(gi.barcode)
            ) || [];
          if (goodsItems && goodsItems.length > 0) {
            goodsItems.forEach((gi) => {
              const checkGoodsIndex = [...checkItem.goods_items].findIndex(
                (cgi) => cgi.barcode == gi.barcode
              );
              checkItem.goods_items[checkGoodsIndex].quantity += gi.quantity;
            });
          }

          const goodsNoItems =
            [...item.goods_items].filter(
              (gi) => !checkGoodsBarcodes.includes(gi.barcode)
            ) || [];
          if (goodsNoItems && goodsNoItems.length > 0) {
            checkItem.goods_items.push(...goodsNoItems);
          }

          data[checkItemIndex] = checkItem;
        } else {
          item.tt = tt;
          data.push(item);
          tt++;
        }
      });
      this.items = [...data];
      this.getGoods();
      // this.validateData(data);
    },
    async onInputFileChange(e) {
      this.errRows = [];
      this.errTexts = [];
      const files = e.target.files;
      if (files && files[0]) {
        const f = files[0];
        this.file = f;
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let values = [];
          switch (this.createFormat) {
            case "ssc":
              values = utils.sheet_to_json(worksheet);
              this.readBySsc(values);
              break;
          }
        };
        await reader.readAsArrayBuffer(f);
      }
    },

    validateData(items) {
      const groupByCustomerOrderId = _.groupBy(items, "customer_order_id");
      Object.keys(groupByCustomerOrderId).forEach((k) => {
        if (groupByCustomerOrderId[k].length > 1) {
          const rows = groupByCustomerOrderId[k].map((g) => g.tt);
          this.errRows.push(...rows);
          this.errTexts.push(
            `<b>[${this.$t("labels.customer_order_id")}]</b>: "${
              k === "undefined" ? "" : k
            }" ${this.$t(
              "labels.duplicate"
            )} : <span class="error--text">${rows.join(", ")}</span>`
          );
        }
      });

      const requiredKeys = [
        "customer_order_id",
        "receiver_name",
        "receiver_phone_number",
        "city_name",
        "county_name",
        "ward_name",
        "address",
        "goods_items",
      ];

      if (this.customer.is_multiple_shop) {
        requiredKeys.unshift("shop");
      }

      const requiredKeyTrans = {
        shop: this.$t("labels.shop"),
        customer_order_id: this.$t("labels.customer_order_id"),
        receiver_name: this.$t("labels.receiver"),
        receiver_phone_number: this.$t("labels.phone_number"),
        city_name: this.$t("labels.city"),
        county_name: this.$t("labels.district"),
        ward_name: this.$t("labels.ward"),
        address: this.$t("labels.address"),
        goods_items: this.$t("labels.product"),
      };
      requiredKeys.forEach((i) => {
        const rows = items.filter(
          (item) =>
            !item[i] ||
            item[i].length === 0 ||
            (i == "address" && item[i].length > 250) ||
            (i == "receiver_name" && item[i].length > 100)
        );
        if (rows.length > 0) {
          const iRows = [...rows].map((r) => r.tt);
          this.errRows.push(...iRows);
          this.errTexts.push(
            `<b>[${requiredKeyTrans[i]}]</b>: ${this.$t(
              "labels.invalid_value"
            )}: <span class="error--text">${iRows.join(", ")}</span>`
          );
        }
      });
    },

    async getGoods() {
      const barcodes = [];

      for (let i = 0; i < this.items.length; i++) {
        const lead = this.items[i];
        const items = [...lead.goods_items];

        for (let j = 0; j < items.length; j++) {
          const item = items[j];
          if (item.barcode && !barcodes.includes(item.barcode)) {
            barcodes.push(item.barcode);
          }
        }
      }

      if (barcodes.length === 0) {
        return false;
      }

      const { data } = await httpClient.post("/get-goods-by-barcodes", {
        barcodes,
      });

      this.goods = [...data];

      this.items = [...this.items].map((lead) => {
        const items = [...lead.goods_items];

        for (let j = 0; j < items.length; j++) {
          const item = items[j];

          const goods = [...this.goods].find(
            (g) => g.customer_goods_barcode == item.barcode
          );
          if (!goods) {
            items[j].id_goods = 0;
          } else {
            items[j].id_goods = goods.id;
          }
        }

        lead.goods_items = items;
        return lead;
      });
    },
    async createLeads() {
      if (this.isDisabledBtnSubmit) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        let fd = new FormData();
        fd.append("file", this.file);
        fd.append("leads", JSON.stringify(this.dataConverted));
        await httpClient.post("/crm-lead-create", fd);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.order_created_success"));
        this.resetAll();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        const validates = (e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.validates) || { errRows: [], errTexts: [] };
        if (validates && validates.errRows && validates.errTexts) {
          const lang = localStorage.getItem("_lang") || "vi";
          this.errRows = [...validates.errRows];
          this.errTexts =
            lang == "vi" ? [...validates.errTexts] : [...validates.errEnTexts];
        }
        this.$vToastify.error(errMsg);
      }
    },
    async getSourceType() {
      const { data } = await httpClient.post("/source-type");
      this.sourceTypes = [...data];
    },
    async getSources() {
      const { data } = await httpClient.post("/source-list");
      this.sources = [...data];
    },
    resetAll() {
      this.items = [];
      this.errRows = [];
      this.errTexts = [];
    },
  },
};
</script>

<style scoped></style>
